<template>
  <div>
    <!-- v-dialog baslıyor -->
    <v-app>
      <!-- v-dialog bitti -->
      <div class="page-card">
        <v-container>
          <v-form v-model="isFormValid">
            <v-row>
              <v-col md="6">
                <div class="form-group">
                  <v-row>
                    <v-col>
                      <v-text-field
                        required
                        v-model="selected.name"
                        label="Adı (*)"
                        filled
                        :rules="rules.name"
                      ></v-text-field> </v-col
                  ></v-row>
                </div>
                <div class="form-group">
                  <v-row>
                    <v-col>
                      <v-text-field
                        v-model="selected.surname"
                        label="Soyadı (*)"
                        filled
                        :rules="rules.name"
                      ></v-text-field
                    ></v-col>
                  </v-row>
                </div>

                <div class="form-group">
                  <v-row>
                    <v-col>
                      <v-text-field
                        v-model="selected.email"
                        label="E-posta (*)"
                        filled
                        :rules="rules.email"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </div>

                <div class="form-group">
                  <v-row>
                    <v-col>
                      <v-text-field
                        v-model="selected.username"
                        label="Kullanıcı Adı (*)"
                        filled
                        :rules="rules.name"
                      ></v-text-field
                    ></v-col>
                  </v-row>
                </div>

                <div class="form-group">
                  <v-row>
                    <v-col>
                      <v-autocomplete
                        auto-select-first
                        clearable
                        :items="adminauthorityitems"
                        v-model="selected.adminAuthority"
                        label="Yetki Seçiniz (*)"
                        filled
                        :rules="rules.authority"
                      ></v-autocomplete>
                    </v-col>
                  </v-row>
                </div>
                <div class="form-group">
                  <v-row>
                    <v-col>
                      <v-autocomplete
                        auto-select-first
                        clearable
                        :items="titleitems"
                        label=" Ünvan Seçiniz"
                        v-model="selected.title"                 
                        filled
                      ></v-autocomplete>
                    </v-col>
                  </v-row>
                </div>

                <div class="form-group">
                  <v-row>
                    <v-col>
                      <v-autocomplete
                        auto-select-first
                        clearable
                        :items="centeritems"
                        item-text="centerName"
                        item-value="id"
                        label=" Merkez Seçiniz"
                        v-model="selected.centerId"
                        filled
                         @change="setCenterOrg"
                                    v-if="hideCenter"
                      ></v-autocomplete>
                    </v-col>
                  </v-row>
                </div>
                <div class="form-group">
                  <v-row>
                    <v-col>
                      <v-autocomplete
                        auto-select-first
                        clearable
                        label="Bölüm Seçiniz"
                        v-model="selected.specialtyDepartment"
                        filled
                        :items="specialtyDepartmentitems"
                        item-text="text"
                        item-value="value"
                        v-if="hideCenter"
                      ></v-autocomplete>
                    </v-col>
                  </v-row>
                </div>
                <div class="form-group">
                  <v-row>
                    <v-col>
                      <v-autocomplete
                        auto-select-first
                        clearable
                        label="Organizasyon Seçiniz.."
                        v-model="selected.organizationId"
                        filled
                        :items="organizationItems"
                        item-text="organizationName"
                        item-value="id"
                         @change="setOrgCenter"
                                    v-if="hideOrg"
                      ></v-autocomplete>
                    </v-col>
                  </v-row>
                </div>
              </v-col>

              <v-col md="6">
                <div class="form-group">
                  <v-row>
                    <v-col>
                      <v-text-field
                        v-model="selected.phoneNumber"
                        label="Cep Telefonu (*)"
                        filled
                        :rules="rules.counter"
                        maxlength="10"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </div>
                <div class="form-group">
                  <v-row>
                    <v-col>
                      <v-text-field
                        v-model="selected.landPhone"
                        label="Sabit Telefon"
                        filled
                        maxlength="10"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </div>
                <div class="form-group">
                  <v-row>
                    <v-col>
                      <v-autocomplete
                        auto-select-first
                        clearable
                        label="Ülke Seçiniz..."
                        v-model="selected.country"
                        filled
                        :items="countryitems"
                        item-text="countryName"
                        item-value="countryId"
                        no-data-text="Liste Boş"
                        @change="getCityByCountryId()"
                      ></v-autocomplete>
                    </v-col>
                  </v-row>
                </div>
                <div class="form-group">
                  <v-row>
                    <v-col>
                      <v-autocomplete
                        auto-select-first
                        clearable
                        label="İl Seçiniz..."
                        v-model="selected.city"
                        filled
                        :items="cityitems"
                        item-text="cityName"
                        item-value="cityId"
                        no-data-text="Lütfen Önce Ülke Seçiniz"
                        @change="getDistrictItems(selected.city)"
                      ></v-autocomplete>
                    </v-col>
                  </v-row>
                </div>
                <div class="form-group">
                  <v-row>
                    <v-col>
                      <v-autocomplete
                        auto-select-first
                        clearable
                        label="İlçe Seçiniz..."
                        v-model="selected.district"
                        filled
                        :items="districtitems"
                        no-data-text="Lütfen Önce İl Seçiniz"
                        item-text="townName"
                        item-value="townId"
                      ></v-autocomplete>
                    </v-col>
                  </v-row>
                </div>
                <div class="form-group">
                  <v-row>
                    <v-col>
                      <v-text-field
                        v-model="selected.postCode"
                        label="Posta kodu"
                        filled
                        :rules="rules.postcode"
                        maxlength="5"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </div>
                <div class="form-group">
                  <v-row>
                    <v-col>
                      <v-textarea
                        v-model="selected.address"
                        label="Adres"
                        filled
                      ></v-textarea>
                    </v-col>
                  </v-row>
                </div>
                <div class="form-group">
                  <v-row>
                    <v-col>
                      <v-menu
                        ref="menu"
                        v-model="menu"
                        :close-on-content-click="false"
                        :return-value.sync="date"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="date"
                            label="Onay Tarihi"
                            append-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            filled
                          >
                          </v-text-field>
                        </template>
                        <v-date-picker v-model="date" no-title scrollable>
                          <v-spacer></v-spacer>
                          <v-btn text color="primary" @click="menu = false">
                            Cancel
                          </v-btn>
                          <v-btn
                            text
                            color="primary"
                            @click="$refs.menu.save(date)"
                          >
                            OK
                          </v-btn>
                        </v-date-picker>
                      </v-menu>
                    </v-col>
                  </v-row>
                </div>
              </v-col>
            </v-row>

            <v-row>
              <v-col xl="2 ml-auto" md="4 ml-auto">
                <button
                  type="button"
                  class="btn btn-primary"
                  @click="send"
                  :disabled="!isFormValid"
                >
                  Güncelle
                </button>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </div>
    </v-app>
  </div>
</template>

<style lang="scss">
//@import "@/assets/sass/pages/wizard/wizard-3.scss";
.v-card-title {
  position: absolute;
  right: 0;
  color: rgb(72, 46, 88);
}
</style>

<script>
//import KTCodePreview from "@/view/content/CodePreview.vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { REMOVE_SELECTEDPAGEITEMNAME } from "@/core/services/store/selectedpageitem.module";
//import KTUtil from "@/assets/js/components/util";
//import KTWizard from "@/assets/js/components/wizard";
import Swal from "sweetalert2";
//import { REGISTER } from "@/core/services/store/auth.module";
import ApiService from "@/core/services/api.service";
import { mapGetters } from "vuex";

export default {
  name: "usersedit",
  data() {
    return {
      isFormValid: false,
      globaladmin: false,
      cro: false,
      user: false,
      objeler: {
        id: "",
      },
      date: new Date().toISOString().substr(0, 10),
      menu: false,
      currentid: null,
      rules: {
        name: [(val) => !!val || "Bu alan doldurulmalı"],
        authority: [(val) => !!val || "Bu alan doldurulmalı"],
        counter: [
          (v) =>
            v.length >= 10 || "Telefon Numaranızı 10 Haneli Olarak Giriniz",
        ],
        postcode: [
          (v) => {
            var regex = /[0-9]{5}/;
            return regex.test(v) || v.length == 0 || "Geçersiz Posta Kodu";
          },
        ],
        email: [
          (v) => {
            const pattern = /^([^<>()[\]\\,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,3}))$/;
            return pattern.test(v) || "Lütfen Geçerli E-posta Adresi Giriniz.";
          },
        ],
      },
      valid: true,
      selected: {
        id: "",
        name: "",
        surname: "",
        usercode: "",
        username: "",
        adminAuthority: "",
        title: "",
        center: "",
        centerId: "",
        organization: "",
        organizationId: "",
        specialtyDepartment: "",
        phoneNumber: "",
        landPhone: "",
        email: "",
        country: null,
        city: null,
        district: null,
        createDate: "",
        deleteDate: "",
        //postCode: "",
        address: "",
      },
      adminauthorityitems: [],
      titleitems: [
        { text: "Prof Dr.", value: "Prof. Dr." },
        { text: "Doç Dr.", value: "Doç Dr." },
        { text: "Uzm. Dr.", value: "Uzm. Dr." },
        { text: "Dr.", value: "Dr." },
        { text: "Yrd. Doç. Dr.", value: "Yrd. Doç. Dr." },
        { text: "Dr. Öğr. Üyesi", value: "Dr. Öğr. Üyesi" },
      ],
      centeritems: [],
      specialtyDepartmentitems: [
        { text: "Kardiyoloji", value: "Kardiyoloji" },
        { text: "Kardiyoloji ABD", value: "Kardiyoloji ABD" },
        { text: "Nefroloji", value: "Nefroloji" },
        { text: "Diğer", value: "Diğer" },
      ],
      countryitems: [],
      cityitems: [],
      districtitems: [],
      selectedCountryId: "",
      selectedCityId: "",
      organizationItems: [],
       hideOrg: true,
      hideCenter: true,
    };
  },
  destroyed() {
    this.$store.dispatch(REMOVE_SELECTEDPAGEITEMNAME);
  },
  mounted() {
    // this.$store.dispatch(SET_BREADCRUMB, [
    //   { title: "Kullanıcı Düzenle", route: "/usersview" },
    // ]);
    let selectedPage = this.$store.state.selectedpageitem.selectedPageItemName;
     console.log("selectedpage",selectedPage);
    if (selectedPage === "") {
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: "Kullanıcı Güncelleme", route: "/usersedit" },
      ]);
    } else {
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: selectedPage, route: "/userlist" },
         { title: "Kullanıcı Güncelleme" },
      ]);
    }
    this.globaladmin = this.isGlobalAdmin;
    this.cro = this.isCro;
    this.user = this.isNormalUser;
    this.isShow = this.globaladmin;
    // this.$on('getCurrentid');
    var temp = localStorage.getItem("itemler");
    this.objeler = JSON.parse(temp);
    this.currentid = this.objeler.id;
    let temp2 = this.$route.params.itemid;
    
    
    this.getCurrentUserinfo(temp2);
    //this.getCountryItems();
    this.getCenterItems();
    this.getOrganization();
    this.fillAdminAuthorityItems();
  },
  methods: {
        setOrgCenter() {
      if (this.selected.organizationId != null) {
         console.log("if e girdi organizasyon dolu");
        this.hideCenter = false;
      } else {
         console.log("if e girdi organizasyon boş");

        this.hideCenter = true;
      }
    },
    setCenterOrg() {
      if (this.selected.centerId != null) {
         console.log("if e girdi merkez dolu");

        this.hideOrg = false;
      } else {
         console.log("if e girdi merkez boş");

        this.hideOrg = true;
      }
    },
    getOrganization() {
      ApiService.get("organizations")
        .then(({ data }) => {
          this.organizationItems = data.result;
          //console.log("organization itemleri geldi", this.organizationItems);
        })
        .catch((err) => {
          console.log("error", err);
        });
    },
    fillAdminAuthorityItems() {
      if (this.globaladmin) {
        this.adminauthorityitems = [
          { text: "Normal User", value: 3 },
          { text: "CRO", value: 1 },
          { text: "Global Admin", value: 2 },
        ];
      } else {
        this.adminauthorityitems = [{ text: "Normal User", value: 3 }];
      }
    },
    getCenterItems() {
      ApiService.get("centers")
        .then(({ data }) => {
          this.centeritems = data.result;
          console.log("centeritem geldi", this.centeritems);
        })
        .catch((err) => {
          console.log("error", err);
        });
    },
    send() {
     
      ApiService.put("/user", this.selected)
        .then(({ data }) => {
          console.log("yeni kayıt güncellendi", data);
          Swal.fire({
            icon: "success",
            title: "Güncelleme işlemi başarılı",
            showConfirmButton: false,
            timer: 1200,
          });
        })
        .catch((err) => {
          console.log("error", err);
          Swal.fire({
            title: "",
            text: err.response.data.message,
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
        });
    },
    getCurrentUserinfo(item) {
      ApiService.get("/user", item)
        .then(({ data }) => {
          console.log("User bilgileri geldi", data);

          this.getCountryItems();
          this.selected = data.result;
         
          // this.selected.center != null
          //   ? null
          //   : (this.selected.center = { id: "" });
          this.selected.city = data.addResult[1].cityId;
          this.selected.country = data.addResult[0].countryId;
          this.selected.district = data.addResult[2].townId;
          if(this.selected.organizationId == null){
              this.hideOrg=false;
          }
          else{
             this.hideCenter=false;
          }
          
          this.getCity(this.selected.country);
          this.getDistrictItems(this.selected.city);
        })
        .catch((err) => {
          console.log("error", err);
        });
    },
    getCountryItems() {
      ApiService.get("countries")
        .then(({ data }) => {
          
          this.countryitems = data;
        })
        .catch((err) => {
          console.log("error", err);
        });
    },
    getCity(selectedCountryId) {
      if (selectedCountryId == null) {
        this.districtitems = [];
      }

      ApiService.get("cities", selectedCountryId)
        .then(({ data }) => {
          this.cityitems = data;
         
        })
        .catch((err) => {
          console.log("error", err);
        });
    },
    getCityByCountryId() {
      this.selected.city = "";
      this.selected.district = "";
      this.districtitems = [];
     
      if (this.selected.country == null) {
        this.cityitems = [];
      }
      this.getCity(this.selected.country);
    },
    getDistrictItems(selectedCityId) {
      if (selectedCityId == null) {
        this.districtitems = [];
      }
      ApiService.get("towns", selectedCityId)
        .then(({ data }) => {
          this.districtitems = data;
        })
        .catch((err) => {
          console.log("error", err);
        });
    },
    getDistrictItemsByCityId(changeSelectedCity) {
      this.selected.district = "";
      let changeSelectedCityId = this.cityitems.filter(
        (city) => city.cityName == changeSelectedCity
      );
     
      ApiService.get("towns", changeSelectedCityId[0].cityId)
        .then(({ data }) => {
          this.districtitems = data;
        })
        .catch((err) => {
          console.log("error", err);
        });
    },
  },
  computed: {
    ...mapGetters(["isGlobalAdmin", "isCro", "isNormalUser"]),
    tab: {
      set(tab) {
        this.$router.replace({ query: { ...this.$route.query, tab } });
      },
      get() {
        console.log("query", this.$route.query.tab);
        return this.$route.query.tab;
      },
    },
  },
};
</script>
